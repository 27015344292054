<template>
    <section>
        <div class="top-container">
        <sprint-nav v-bind:projectId="projectId" v-bind:iteration="iteration"></sprint-nav>
        <h1 class="title">Sprint {{ iteration }} Backlog</h1>
        <notification-list />
        <loading-spinner v-if="isLoading" />
        
            <div class="button-group">
                <button type="button" class="btn" @click="showModal('STORY')" v-bind:disabled="isDisabled">
                    <font-awesome-icon class="img-icon story-color" v-bind:icon="$store.getFontAwesomeIcon('STORY')" />
                    Create Story
                </button>

                <button type="button" class="btn" @click="showModal('DEFECT')" v-bind:disabled="isDisabled">
                    <font-awesome-icon class="img-icon defect-color" v-bind:icon="$store.getFontAwesomeIcon('DEFECT')" />
                    Create Defect
                </button>
            </div>
        </div>

        <BaseModal v-if="isModalVisible" @close="isModalVisible = false">
            <template v-slot:body>
                <card-form v-bind:cardInput="cardInput" v-on:saveCard="createCard"></card-form>
            </template>
        </BaseModal>
        <card-table v-if="doesSprintHaveCards" v-bind:cards="cards" v-bind:users="users" v-bind:sprints="sprints"
            v-bind:cardTypes="cardTypes" v-bind:cardStates="cardStates" v-on:deleteCardEvent="handleDeleteCardEvent" />
        <div v-if="isSprintEmpty" class="no-items">
            <img class="no-items-image" src="../assets/another-castle.jpg" />
            <h2>Sorry, but your cards are at another castle.</h2>
            <p>Visit the
                <router-link v-bind:to="{ name: 'product-backlog' }">
                    Product Backlog
                </router-link>
                to assign cards to this Sprint.
            </p>
        </div>
        <div v-if="isDisabled" class="no-items">
            <img class="no-items-image" src="../assets/pylons.png" />
            <h2>You must construct additional pylons</h2>
            <p>This sprint does not exist. Please select a sprint from the
                <router-link v-bind:to="{ name: 'sprints', params: { projectId: projectId } }">
                    Sprints
                </router-link> page.
            </p>
        </div>
    </section>
</template>

<script>
import projectService from "../services/ProjectService";
import CardTable from "../components/CardTable";
import LoadingSpinner from "../components/LoadingSpinner";
import SprintNav from "@/components/SprintNav.vue";
import NotificationList from "@/components/NotificationList.vue";
import BaseModal from "@/components/BaseModal.vue";
import CardForm from "@/components/CardForm.vue";

export default {
    name: 'sprint-backlog',
    components: { CardTable, LoadingSpinner, SprintNav, NotificationList, BaseModal, CardForm },
    created() {
        this.loadCards(this.projectId, this.iteration);
    },
    computed: {
        isSprintEmpty() {
            return !this.isDisabled && this.cards?.length === 0;
        },
        doesSprintHaveCards() {
            return this.cards?.length > 0;
        }
    },
    data() {
        return {
            isLoading: true,
            users: [],
            cards: [],
            cardTypes: [],
            cardStates: [],
            sprints: [],
            projectId: this.$route.params.projectId,
            iteration: this.$route.params.iteration,
            isModalVisible: false,
            isDisabled: false
        };
    },
    methods: {
        handleDeleteCardEvent(card) {
            const index = this.cards.indexOf(card);
            if (index > -1) {
                this.cards.splice(index, 1);
            }
        },
        createCard(card) {
            projectService.createCard(this.projectId, card).then((response) => {
                const cardDisplay = response.data;
                const savedCard = cardDisplay.card;
                this.cards.unshift(savedCard);
                this.$store.commit("ADD_NOTIFICATION", {
                    type: "success",
                    message: "Card was successfully created",
                });
                this.isModalVisible = false;
            }).catch((error) => {
                this.isBacklogLoaded = true;
                let response = error.response;
                if (response?.status === 401) {
                    // Token expired
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Session timeout. Please login again.",
                    });
                    this.$store.commit("LOGOUT");
                    this.$router.push({ name: "login" });
                }
                else {
                    // Something else unexpected happened
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Sorry, something unexpected occurred. Please try again later.",
                    });
                    console.error("Creating card was unsuccessful: ", response?.message);
                }
            });

        },
        showModal(cardType) {
            this.cardInput = {
                cardType: cardType,
                projectId: this.projectId,
                cardState: 'NEW',
                sprint: this.iteration
            }
            this.isModalVisible = true;
        },
        loadCards(projectId, iteration) {
            this.isLoading = true;
            projectService.getSprintBacklog(projectId, iteration)
                .then((response) => {
                    const backlogDisplay = response.data;
                    this.cards = backlogDisplay.cards;
                    this.cardTypes = backlogDisplay.cardTypes;
                    this.cardStates = backlogDisplay.cardStates;
                    this.users = backlogDisplay.projectUsers;
                    this.sprints = backlogDisplay.projectSprints;
                    this.isLoading = false;
                    this.display404 = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    } else if (response?.status === 404) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "This sprint does not exist",
                        });
                        this.isDisabled = true;
                    } else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error("Getting backlog was unsuccessful: ", response?.message);
                        this.isButtonsDisabled = true;

                    }
                });

        },
    }
};
</script>
<style scoped>
.button-group {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.img-icon {
    margin: 0 4px;
}

.btn {
  font-size: 20px;
  background-color: transparent;
  padding: 1%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

.no-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.no-items-image {
    width: 20rem;
    height: 20rem;
    border-radius: 20rem;
}

.top-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    padding: 3%;
    border: 0.5px solid white;
    border-radius: 5px;
    background-color: rgba(18, 18, 18, 0.75);
}

.title {
    margin-top: 5%;
    margin-bottom: 2%;
}
</style>
