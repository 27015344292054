import axios from 'axios';

export default {

  deleteCard(cardId) {
    return axios.delete(`/api/cards/${cardId}`)
  },
  deleteProject(projectId) {
    return axios.delete(`/api/projects/${projectId}`);
  },

  joinProject(projectId) {
    return axios.post(`/api/projects/${projectId}/join`);
  },

  leaveProject(projectId) {
    return axios.post(`/api/projects/${projectId}/leave`);
  },

  getMyProjects() {
    return axios.get('/api/projects/user');
  },

  getAllProjects() {
    return axios.get('/api/projects');
  },

  getAllTemplates() {
    return axios.get('/api/templates');
  },

  getProjectSettings(projectId) {
    return axios.get(`/api/projects/${projectId}`);
  },

  createProject(project) {
    return axios.post('/api/projects', project);
  },

  saveProject(projectId, project) {
    return axios.put(`/api/projects/${projectId}`, project);
  },

  getProjectBacklog(projectId) {
    return axios.get(`/api/projects/${projectId}/cards`);
  },

  getSprintBacklog(projectId, iteration) {
    return axios.get(`/api/projects/${projectId}/sprints/${iteration}`);
  },

  getSprintTaskboard(projectId, iteration) {
    return axios.get(`/api/projects/${projectId}/sprints/${iteration}/taskboard`);
  },

  getSprintsForProject(projectId) {
    return axios.get(`/api/projects/${projectId}/sprints`);
  },

  getUsersForProject(projectId) {
    return axios.get(`/api/projects/${projectId}/users`);
  },

  getCard(projectId, cardId) {
    return axios.get(`/api/projects/${projectId}/cards/${cardId}`);
  },

  patchCard(projectId, cardId, card) {
    return axios.patch(`/api/projects/${projectId}/cards/${cardId}`, card);
  },

  createCard(projectId, card) {
    return axios.post(`api/projects/${projectId}/cards`, card);
  },

  saveCard(projectId, cardId, card) {
    return axios.put(`/api/projects/${projectId}/cards/${cardId}`, card);
  },

  createSprint(projectId) {
    return axios.post(`/api/projects/${projectId}/sprints`);
  }
}
