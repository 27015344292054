import axios from 'axios';

export default {

  login(user) {
    return axios.post('/api/login', user)
  },

  register(user) {
    return axios.post('/api/register', user)
  },

  getUserProfile(userId) {
    return axios.get(`/api/users/${userId}`);
  },

  updateUserProfile(user) {
    return axios.put('/api/users', user);
  },

  changePassword(passwordDto){
    return axios.post('/api/users/changePassword', passwordDto);
  }

}
