<template>
    <nav class="mini-nav">
        <router-link class="style" v-bind:to="{ name: 'product-backlog'}">Product Backlog</router-link>        
        <router-link class="style" v-bind:to="{ name: 'sprints', params: { projectId: projectId } }">
            Sprints
        </router-link>
        <router-link class="style" v-if="iteration"
            v-bind:to="{ name: 'sprint-backlog', params: { projectId: projectId, iteration: iteration } }">
            Sprint Backlog
        </router-link>
        <router-link class="style" v-if="iteration"
            v-bind:to="{ name: 'taskboard', params: { projectId: projectId, iteration: iteration } }">
            Sprint Taskboard
        </router-link>
    </nav>
</template>

<script>
export default {
    name: 'sprint-nav',
    props: ['projectId', 'iteration']
}
</script>

<style scoped>
.mini-nav {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.style {
    color: rgb(202, 3, 3);
    font-size: large;
}
</style>