<template>
    <form class="card-form" v-on:submit.prevent="submitCard()">
        <header class="card-header" v-bind:class="card.cardType?.toLowerCase()">
            <div class="header-row">
                <span>{{ card.cardType }}</span>
                <span>{{ card.id }}</span>
            </div>
            <div class="header-row">
                <font-awesome-icon class="img-icon color" v-bind:class="card.cardType?.toLowerCase() + '-color'"
                    v-bind:icon="$store.getFontAwesomeIcon(card.cardType)" />
                <h2>{{ card.id }} {{ card.cardType }} {{ card.name }}</h2>
            </div>
        </header>
        <section class="status-bar content-cell">
            <div>
                <label class="top-labels" for="cardType">Type: {{ card.cardType }}</label>
            </div>
            <div>
                <label class="top-labels" for="cardState">State: {{ card.cardState }}</label>
            </div>
            <div>
                <label class="top-labels" for="sprint">Sprint: {{ card.sprint }}</label>
            </div>
            <button class="save-button" type="submit">
                <font-awesome-icon class="img-icon" icon="fa-floppy-disk" />
                Save Card
            </button>

        </section>

        <section class="main-content content-cell">
            <h3 class="content">{{ card.cardType == 'DEFECT' || card.cardType == 'BUG' ? 'Steps to reproduce' : 'Content' }}</h3>
            <div class="form-control">
                <label class="red" for="name">Display Name:</label>
                <input class="text-input" type="text" id="name" v-model="card.name" size="120" autofocus />
            </div>
            <div class="form-control">
                <label class="red" for="description">Description:</label>
                <textarea class="text-input" id="description" rows="8" cols="120" v-model="card.description" />
            </div>
            <div v-if="card.cardType === 'STORY'" class="form-control">
                <label class="red" id="acceptance-label" for="acceptanceCriteria">Acceptance Criteria:</label>
                <textarea class="text-input" id="acceptanceCriteria" rows="8" cols="120" v-model="card.acceptanceCriteria" />
            </div>
        </section>

        <section class="planning content-cell">
            <h3 class="gap">Planning:</h3>
            <div v-if="card.cardType === 'STORY' || card.cardType === 'DEFECT'" class="form-control">
                <label class="red" for="storyPoints">Story Points</label>
                <select-story-points id="storyPoints" v-model="card.storyPoints"></select-story-points>
            </div>
        </section>

        <section class="related content-cell">
            <h3 class="gap">Related:</h3>
            <div class="form-control">
                <label class="red" for="parentId">Parent Id</label>
                <input id="parentId" type="number" min="1" v-model="card.parentId" disabled="true" />
            </div>
        </section>
    </form>
</template>
  
<script>
import SelectStoryPoints from './SelectStoryPoints.vue';
export default {
    name: 'card-form',
    components: { SelectStoryPoints },
    props: ['cardInput'],
    data() {
        return {
            card: {}
        }
    },
    created() {
        this.card.id = this.cardInput?.id;
        this.card.name = this.cardInput?.name;
        this.card.cardType = this.cardInput?.cardType;
        this.card.cardState = this.cardInput?.cardState;
        this.card.description = this.cardInput?.description;
        this.card.acceptanceCriteria = this.cardInput?.acceptanceCriteria;
        this.card.storyPoints = this.cardInput?.storyPoints;
        this.card.projectId = this.cardInput?.projectId;
        this.card.assignedId = this.cardInput?.assignedId;
        this.card.parentId = this.cardInput?.parentId;
        this.card.children = this.cardInput?.children ? this.card.children.slice() : [];
        this.card.parent = this.cardInput?.parent ? this.card.parent : null;
        this.card.sprint = this.cardInput?.sprint;
    },
    methods: {
        submitCard() {
            this.$emit('saveCard', this.card);
        }
    }
};
</script>
  

<style scoped>
main {
    padding: 1rem;
}

label {
    font-size: large;
}

.red:hover {
    color: rgb(202, 3, 3);
}

.text-input {
    max-width: 80vw;
}

.card-form {
    min-width: 375;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
        "header header"        
        "status status"        
        "main main"
        "planning related";        
}

.content-cell {
    display:flex;
    align-items: flex-start;
    background-color: rgba(18, 18, 18, 0.95);
}

.card-header {
    grid-area: header;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    gap: .2rem;
    width: 100%;
    background-color: rgba(18, 18, 18, 0.95);
}

.status-bar {
    grid-area: status;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
    background-color: rgba(18, 18, 18, 0.95);
}

.main-content {
    grid-area: main;
    align-items: flex-start;
    background-color: rgba(18, 18, 18, 0.95);
}

.planning {
    grid-area: planning;
}

.related {
    grid-area: related;
}

.save-button {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .2rem;
    align-items: center;
    padding: 5px;
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 5px;
    color: white;
    font-family: "MedievalSharp", cursive;
    height: 110%;
    font-size: 15px;
}

.save-button:hover {
    color: rgb(202, 3, 3);
}

.header-row {
    display: flex;
    flex-direction: row;
    gap: .2rem;
    align-items: center;
}

.form-control {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-align: left;
    flex-grow: 1;
}

.form-control label {
    border-bottom: 1px solid;
}

.gap {
    margin-bottom: 25px;
    font-size: 20px;
    color: rgb(202, 3, 3);
}

.content {
    margin-bottom: 5%;
    font-size: 20px;
    color: rgb(202, 3, 3);
    text-decoration: underline;
    gap: 2px;
}

.top-labels {
    font-size: 20px;
}

.top-labels:hover {
    color: rgb(132, 45, 60);
}

.img-icon {
    color: rgb(132, 45, 60);
}

#description {
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 5px;
    color: rgb(255, 34, 0);
    font-family: "MedievalSharp", cursive;
    height: 70%;
}

#description:focus {
   border: solid 5px rgb(202, 3, 3);
}

#acceptanceCriteria {
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 5px;
    color: rgb(255, 34, 0);
    font-family: "MedievalSharp", cursive;
    height: 70%;
}

#acceptanceCriteria:focus {
   border: solid 5px rgb(202, 3, 3);
}

#storyPoints {
    background-color: rgba(18, 18, 18, 0.30);
    color: white;
    border: 0.5px solid white;
    border-radius: 5px;
    width: 100%;
    height: 35px;
}

#parentId {
    width: 40%;
    height: 35px;
}

@media only screen and (min-width: 1024px) {
    .card-form {
        
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "header header header"
            /* note lack of semicolon! */
            "status status status"
            /* note lack of semicolon! */
            "main planning related";
        width: 100%;
    }

    .text-input {
        max-width: 515px;
    }
    
}

</style>