<template>
  <article v-if="card" class="mini-card" v-bind:class="card.cardType?.toLowerCase()">
    <div class="mini-card-header">
      <font-awesome-icon class="img-icon" v-bind:class="card.cardType?.toLowerCase()+'-color'" v-bind:icon="$store.getFontAwesomeIcon(card.cardType)" />
      <router-link  v-bind:to="{ name: 'edit-card-view', params: { projectId: card.projectId, cardId: card.id } }">
        <p class="mini-card-name">{{ card.id }} {{ getTruncatedName(card.name) }}</p>
      </router-link>
    </div>
    <div class="user-column">
      <div class="card-title">Developers:</div>
      <select-card-user class="select-drop" v-on:input="emitEvent($event, 'assigned')" v-bind:users="users" v-bind:selectedUser="card.assigned"></select-card-user>
      <select-card-user class="select-drop" v-on:input="emitEvent($event, 'partner')" v-bind:users="users" v-bind:selectedUser="card.partner"></select-card-user>
      <div class="card-title">Tester:</div>
      <select-card-user class="select-drop" v-on:input="emitEvent($event, 'tester')" v-bind:users="users" v-bind:selectedUser="card.tester"></select-card-user>
    </div>
    <div class="mini-card-row">
      <div class="card-title">State:</div>
      <div class="indicator" v-bind:class="card.cardState?.toLowerCase()">&nbsp;</div>
      <div class="state-name">{{ prettyState }}</div>
            
    </div>
  </article>
  <article v-else class="mini-card">
    <div class="mini-card-header">
        Unparented Tasks
    </div>
  </article>
</template>
  
<script>
import SelectCardUser from './SelectCardUser.vue';
export default {
  name: "mini-card",
  components: { SelectCardUser },
  props: ["card", "users"],
  data() {
    return {

    }
  },
  methods: {
    hasImage(selectedUser) {
      return !!selectedUser?.profileImageUrl;
    },
    getTruncatedName(name){
      if (name.length > 42){
        return name.substring(0, 42) + '...';
      }
      return name;
    },
    getDisplayName() {
      if (!this.card.assigned) {
        return 'Unassigned';
      }
      const user = this.card.assigned;
      return user?.displayName ? user.displayName : user.username;
    },    
    emitEvent(event, userType){
      const payload = {
        userType: userType,
        selectedId: event.target.value
      }
      this.$emit('userSelect', payload);
    }
  },
  computed: {
    prettyState() {
      if (this.card?.cardState) {
        return this.card.cardState.substring(0, 1) + this.card.cardState.substring(1).toLowerCase();
      }
      return '---';
    }
  }
};
</script>
  
<style scoped>
.mini-card {
  display: flex;
  text-align: left;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px;
  width: 10rem;
  padding: 5px;
  background-color: rgba(18, 18, 18, 0.75);
  font-size: 75%;
  border-right: .5px solid white;
  border-top: .5px solid white;
  border-bottom: .5px solid white;
}

.mini-card-name {
  line-height: 125%;
  color: rgb(214, 214, 214) !important;
}

.idText {
  font-weight: bold;  
}

.mini-card-header {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 17px;
  color: rgb(202, 3, 3) !important;
}

.mini-card-row {
  padding: 5px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.user-column {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.indicator {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 1px gray groove;
  background-color: white; 
}

.state-name {
  font-size: 15px;
}

.new {
  background-color: gainsboro;
}

.active {
  background-color:dodgerblue;
}

.resolved {
  background-color: orange;
}

.closed {
  background-color: green;
}

.select-drop {
  display: flex;
  align-items: center;
}

.card-title {
  font-size: large;
}
</style>