<template>
    <div id="spinner">        
        <img v-bind:src="image"/>
    </div>
</template>
    
<script>
    import spinner from "@/assets/sun-spinner.gif"

export default {
    name: 'loading-spinner',
    data() {
        return {
            image: spinner
        }
    }
}
</script>
    
<style scoped>
#spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>