<template>
  <main class="main-content-container">
    <div class="projects-container">
    <h1 class="page-title">Public Projects</h1>
    <img id="logo" alt="Crusader Games logo" src="../assets/logo.png">
    <notification-list />
    <loading-spinner v-if="isLoading" />
    <project-table v-bind:projects="projects" publicView="true" v-on:clickEvent="handleJoinEvent" />
  </div>
  </main>
</template>

<script>
import projectService from "../services/ProjectService";
import ProjectTable from "../components/ProjectTable.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NotificationList from "../components/NotificationList.vue";

export default {
  components: { ProjectTable, LoadingSpinner, NotificationList },
  created() {
    this.loadProjects();
  },
  data() {
    return {
      isLoading: true,
      projects: [],
      //   showAddEditForm: false
    };
  },
  methods: {
    handleJoinEvent(project) {
      this.isLoading = true;
      projectService.joinProject(project.id).then(() => {
        this.$store.commit("ADD_NOTIFICATION", {
          type: "success",
          message: `You successfully joined '${project.name}'`,
        });
        this.isLoading = false;
      })
    },
    loadProjects() {
      this.isLoading = true;
      projectService
        .getAllProjects()
        .then((response) => {
          this.projects = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else {
            // Something else unexpected happened
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message:
                "Sorry, something unexpected occurred. Please try again later.",
            });
            console.error(
              "Getting projects was unsuccessful: ",
              response?.message
            );
          }
        });
    }
  },
};
</script>

<style scoped>

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 5%;
  padding-top: 5%;
  border: 10px white solid;
  border: 0.5px solid white;
  border-radius: 3px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  background-color: rgba(18, 18, 18, 0.75);
  font-size: 17px;
}

.page-title {
  font-family: "MedievalSharp", cursive;
  padding-bottom: 5%;
}

#logo {
  margin-bottom: 4%;
}
</style>