<template>
  <div id="devboard-app">
    <app-header id="app-header" title="Crusader Games - Developer Portal"/>
    <main-nav id="main-nav" v-if="user"/>
      <main id="main-content">
        <DialogWindow />
        <router-view />
      </main>
    <app-footer id="app-footer"/>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import MainNav from './components/MainNav.vue';
import DialogWindow from './components/DialogWindow.vue';

export default {
  components: { MainNav, AppFooter, AppHeader , DialogWindow},
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

#app {
  font-family: "MedievalSharp", cursive;
  font-weight: bold;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
}

</style>
