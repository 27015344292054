<template>
  <form id="form-login" v-on:submit.prevent="register">
      <div class="form-control">
        <label class="register-text" for="username">Username:</label>
        <input type="text" id="username" size=50 v-model="user.username" required autofocus />
      </div>
      <div class="form-control">
        <label class="register-text" for="password">Password:</label>
        <input type="password" id="password" size=50 v-model="user.password" required />
      </div>
      <div class="form-control">
        <label class="register-text" for="confirm-password">Confirm Password:</label>
        <input type="password" id="confirm-password" size=50 v-model="user.confirmPassword" required />
      </div>
      <div class="bottom-flex">
      <button class="btn" type="submit">Register</button>
      <router-link v-bind:to="{ name: 'login' }" class="new-route">Have an account? Log-in</router-link>
      </div>
    </form>
</template>

<script>
import userService from "../services/UserService";

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
        confirmPassword: '',
        role: 'user'
      }
    };
  },
  methods: {
    register() {
      // Clear any previous errors
      this.$store.commit('CLEAR_ERROR_NOTIFICATIONS');

      if (this.user.password != this.user.confirmPassword) {
        this.$store.commit('ADD_NOTIFICATION', {type: 'error', message: 'Password & Confirm Password do not match.'});
      } else {
        userService
          .register(this.user)
          .then((response) => {
            if (response.status == 201) {
              this.$store.commit('ADD_NOTIFICATION', {type: 'success', message: 'Registration successful. Please log-in.'});
              this.$router.push({
                name: 'login'
              });
            }
          })
          .catch((error) => {
            const response = error.response.data;
            if (response?.status === 400) {
              // Validation failed, use message from response
              this.$store.commit('ADD_NOTIFICATION', {type: 'error', message: response.message});
            } else {
              // Something else unexpected happened
              this.$store.commit('ADD_NOTIFICATION', {type: 'error', message: 'Sorry, something unexpected occurred. Please try again later.'});
            }
          });
      }
    }
  }
}
</script>

<style scoped>
form {
  margin-bottom: 1rem;
  max-width: 40rem;
}

label {
  text-align: right;
  display: inline-block;
  margin-right: 15px;
  width: 150px;
}

input {
  display: inline-block;
}

button {
  margin-left: 165px;
  margin-right: 15px;
  padding: 0.25rem 0.5rem;
}

.form-control {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: large;
}

.btn {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 1%;
  margin-bottom: 2%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

#username:focus {
  border: solid 4px rgb(202, 3, 3);
}

#password:focus {
  border: solid 4px rgb(202, 3, 3);
}

#confirm-password:focus {
  border: solid 4px rgb(202, 3, 3);
}

.bottom-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.new-route {
  color: rgb(202, 3, 3);
  font-size: 18px;
}

.register-text {
 font-size: 25px;
}

</style>