<template>
  <section>
    <div class="sprints-container">
      <div class="sprint-nav-container">
        <sprint-nav />
      </div>
      <h1>Sprints</h1>
      <notification-list />
      <loading-spinner v-if="isLoading" />
      <sprint-table v-if="!isError" v-bind:sprints="sprints" />
      <div v-else>
      <img class="no-items-image" src="../assets/luigi.jpg" />
      <h2>Luigi does not approve.</h2>
      <p>This project does not exist. Please create a project at
        <router-link v-bind:to="{ name: 'myProjects' }">My Projects</router-link> page.
      </p>
    </div>
    </div>
    <button v-on:click="createNewSprint()" v-bind:disabled="isError">Create New Sprint</button>
  </section>
</template>

<script>
import projectService from "../services/ProjectService";
import SprintTable from "../components/SprintTable";
import LoadingSpinner from "../components/LoadingSpinner";
import NotificationList from "@/components/NotificationList.vue";
import SprintNav from "@/components/SprintNav.vue";

export default {
  components: { SprintTable, LoadingSpinner, NotificationList, SprintNav },
  created() {
    this.loadSprints(this.projectId);
  },
  data() {
    return {
      isLoading: true,
      projectId: this.$route.params.projectId,
      sprints: [],
      isError: false,
    };
  },
  methods: {
    createNewSprint() {
      this.isLoading = true;
      projectService.createSprint(this.projectId).then(response => {
        const sprint = response.data;
        this.sprints.push(sprint);
        this.isLoading = false;
      })
        .catch(error => {
          this.isLoading = false;
          let response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else {
            // Something else unexpected happened
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message:
                "Sorry, something unexpected occurred. Please try again later.",
            });
            console.error(
              "Saving Sprint was unsuccessful: ",
              response?.message
            );
          }
        });
    },
    loadSprints(projectId) {
      this.isLoading = true;
      projectService
        .getSprintsForProject(projectId)
        .then((response) => {
          this.sprints = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else if (response?.status === 404) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "This project does not exist",
            });
            this.isError = true;
          } else {
            // Something else unexpected happened
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message:
                "Sorry, something unexpected occurred. Please try again later.",
            });
            console.error(
              "Getting projects was unsuccessful: ",
              response?.message
            );
            this.isError = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.sprints-container {
  border: 0.5px solid white;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.75);
  margin: 3%;
  padding: 5%;
}

.sprint-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  padding-bottom: 2%;
}

.no-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.no-items-image {
    width: 20rem;
    height: 20rem;
    border-radius: 20rem;
}

button {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 1%;
  margin-bottom: 2%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

button:hover {
  color: rgb(202, 3, 3);
}
</style>