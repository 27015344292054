<template>
    <table>
        <tr v-for="sprint in sprints" v-bind:key="sprint.iteration">
            <td>{{ sprint.projectId }}-{{ sprint.iteration }}</td>
            <td>{{ sprint.startDate }}</td>
            <td>{{ sprint.endDate }}</td>
            <td>
                <router-link class="btn"
                    v-bind:to="{ name: 'sprint-backlog', params: { projectId: sprint.projectId, iteration: sprint.iteration } }">
                    Sprint Backlog
                </router-link>
            </td>
            <td> 
                <router-link class="btn"
                    v-bind:to="{ name: 'taskboard', params: { projectId: sprint.projectId, iteration: sprint.iteration } }">
                    Sprint Taskboard
                </router-link>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    name: "sprint-table",
    props: ["sprints"],
}
</script>

<style scoped>
td {
    font-size: large;
    padding-bottom: 5%;
}

.btn {
  border: 0.2px solid white;
  background-color: transparent;
  color: white;
  padding: 8px;
  font-size: 15px;
  text-decoration: none;
  font-family: "MedievalSharp", cursive;
  border-radius: 3px;
  font-weight: 550;
}

.btn:hover {
  filter: brightness(85%);
  transition: ease-in-out .3s;
  color: rgb(202, 3, 3);
}   

</style>