<template>
    <!-- Emit is used by v-onModel -->
    <select  v-on:input="$emit('update:modelValue', sanitize($event.target.value))" name="sprint">      
        <option value="0">Not Assigned</option>        
        <option v-for="sprint in sprints" v-bind:key="sprint.iteration" v-bind:selected="sprint.iteration == modelValue" v-bind:value="sprint.iteration">{{ getDisplayName(sprint)}}</option>        
    </select>
</template>

<script>
  export default {
    name: "select-sprint",
    props: ["sprints", "modelValue"],
    methods: {      
      sanitize(value) {
        if (value) {
          return parseInt(value);
        } else {
          return null;
        }
      },
    getDisplayName(sprint){
      return `Sprint ${sprint.iteration}`
    }
    }
  };
  </script>