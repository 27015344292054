<template>
  <header id="app-header">
    <div id="app-info">
      <img class="app-logo" src="@/assets/logo.png" alt="app logo" />
      <router-link v-bind:to="{ name: 'HomeView' }"><h1>{{ title }}</h1></router-link>
    </div>
    <div id="user-info">
      <div id="user-logout">
        <router-link v-bind:to="{ name: 'myProfile' }">
          <div class="user-name">{{ user.displayName ? user.displayName : user.username }}</div>
        </router-link>
        <router-link v-bind:to="{ name: 'logout' }" v-if="$store.state.token != ''" class="login">Logout</router-link>
          <router-link v-bind:to="{ name: 'login' }" v-else class="login">Login</router-link>
    
      </div>
      <router-link v-bind:to="{ name: 'myProfile' }">
        <img v-if="user.profileImageUrl" class="img-profile" v-bind:src="user.profileImageUrl" alt="profile image" />
        <font-awesome-icon v-else class="img-profile" icon="fa-solid fa-user" />
      </router-link>
    </div>
    
  </header>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>
* {
  color: rgb(199, 199, 199) !important;
}

.login {
  font-size: 150%;
}

#app-header {
  display: grid;
  grid-template-columns: 3fr 50px minmax(150px, 1fr);
  grid-template-rows: 50px;
  padding: 5px;
  background-image: linear-gradient(rgb(22, 18, 8), rgb(140, 0, 0));
  border-bottom: 3px ridge rgb(140, 0, 0);
}

#app-info {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
}

#user-info {
  display: flex;
  gap: 1rem;
  grid-column: 3 / 4;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

#user-logout {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.user-name {
  margin-bottom: 0.25rem;
  font-size: 1.2rem;
}

.img-profile {
  height: 40px;
  margin-right: 1rem;
  border-radius: 50%;
}

</style>