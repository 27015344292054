<template>
  <div class="table-container">
    
    <div class="table">
      <div class="table-row">
        <div class="col small">Id</div>
        <div class="col">Card Type</div>
        <div class="col">Name</div>
        <div class="col">Card State</div>
        <div class="col small">Story Points</div>
        <div class="col">Sprint</div>
        <div class="col">Parent</div>
    </div>
    <div class="table-row" v-for="card in cards" v-bind:key="card.id">
      <div class="col small id-col space">{{ card.id }}</div>
      <div class="col space">
        {{ card.cardType }}
        <!-- <select v-on:change="handleChange(card.projectId, card.id, { cardType: $event.target.value })" name="type">
                    <option v-for="option in cardTypes" v-bind:key="option" v-bind:selected="card.cardType == option">{{
                        option }}</option>
                </select> -->
      </div>
      <div 
        v-bind:class="{ child: isChild(card), grandchild: isGrandChild(card) }"
        class="col"
      >
        <router-link class="name card-name"
          v-bind:to="{
            name: 'edit-card-view',
            params: { projectId: card.projectId, cardId: card.id },
          }"
        >
          <font-awesome-icon
            class="img-icon color"
            v-bind:class="card.cardType?.toLowerCase() + '-color'"
            v-bind:icon="$store.getFontAwesomeIcon(card.cardType)"
          />
          {{ card.name }}
        </router-link>
      </div>
      <div class="col">
        <select
          v-on:change="
            handleChange(card.projectId, card.id, {
              cardState: $event.target.value,
            })
          "
          name="cardState"
        >
          <option
            v-for="option in cardStates"
            v-bind:key="option"
            v-bind:selected="card.cardState == option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="col small">{{ card.storyPoints }}</div>
      <div class="col">
        <select-sprint
          v-on:change="
            handleChange(card.projectId, card.id, {
              sprint: $event.target.value,
            })
          "
          v-bind:sprints="sprints"
          v-bind:modelValue="card.sprint"
        ></select-sprint>
      </div>
      <div class="col">
        <router-link class="name"
          v-if="card.parent?.name"
          v-bind:to="{
            name: 'edit-card-view',
            params: { projectId: card.projectId, cardId: card.parentId },
          }"
        >
          {{ card.parent.name }}</router-link
        >
      </div>
      <div class="col">
        <button class="deleteButton" v-if="isMember" v-on:click="handleDeleteRequest(card)">
          <font-awesome-icon class="img-icon" icon="fa-trash-can" />
          Delete
        </button>
      </div>
    </div>
  </div>
  </div>
  
</template>

<script>
import projectService from "../services/ProjectService";
import SelectSprint from "../components/SelectSprint";
import dialog from "@/dialog";

export default {
  name: "card-table",
  components: { SelectSprint },
  props: ["cards", "users", "sprints", "cardTypes", "cardStates"],
  computed: {
    isMember() {
      if (!this.users){
        return false;
      }
      return this.users.some(user => this.$store.state.user.id == user.id);
    }
  },
  methods: {
    async handleDeleteRequest(card){
      const confirmed = await dialog.okText('Yes').cancelText('No').confirm(`Are you sure you want to delete ${card.id}:${card.name}?`);
      if (confirmed) {
        this.deleteCard(card);
      }
    },
    deleteCard(card){
      projectService.deleteCard(card.id).then(() => {
        this.$store.commit("ADD_NOTIFICATION", {
          type: "success",
          message: `You deleted '${card.id}'`,
        });
        this.$emit('deleteCardEvent', card);
      }).catch((error) => {
        this.isLoading = false;
        let response = error.response;
        if (response?.status === 401) {
          // Token expired
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message: "Session timeout. Please login again.",
          });
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "login" });
        } else {
          // Something else unexpected happened
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message:
              "Sorry, something unexpected occurred. Please try again later.",
          });
          console.error(
            "Deleting project was unsuccessful: ",
            response?.message
          );
        }
      });

    },
    handleChange(projectId, cardId, card) {
      projectService.patchCard(projectId, cardId, card).then(() => {});
    },
    isChild(card) {
      return card.cardType === "STORY" || card.cardType === "DEFECT";
    },
    isGrandChild(card) {
      return card.cardType === "TASK" || card.cardType === "BUG";
    },
  },
};
</script>

<style scoped>
select {
  background-color: rgba(18, 18, 18, 0.75);
  color: white;
  padding: 4px;
  border: 0.5px solid white;
  border-radius: 6px;
  height: 35px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

select:hover {
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
  color: rgb(202, 3, 3);
}

.img-icon {
  height: 1rem;
  width: 1rem;
  color: rgb(132, 45, 60);
}

.child {
  padding-left: 1em;
}

.grandchild {
  padding-left: 2em;
}

.deleteButton {
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 8px;
  border: 0.5px solid white;
  color: white;
  border-radius: 6px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.table-container {
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
  border: 0.5px solid white;
  background-color: rgba(18, 18, 18, 0.75);
  border-radius: 4px;
  padding: 15px;
  padding-top: 2%;
  font-size: 18px;
  max-width: 100%;
}

.name {
  color: rgb(202, 3, 3) !important;
  text-decoration: none;
}

.table-row{
  display: flex;
  margin-bottom: 8%;
}

.col {
  flex: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.id-col {
  margin-left: 4%;
}

.small {
  flex: .5;
}

.color {
  color: rgb(66, 66, 197);
}

</style>