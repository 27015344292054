<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <section class="modal-body" id="modalDescription">
                    <button type="button" class="btn-close" v-on:click="close" aria-label="Close modal">
                        x
                    </button>
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'base-modal',
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
}

.modal-body {
    position: relative;
    padding: 0px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding-top: 5px;
    padding-right: 15px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    color: rgb(132, 45, 60);
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

@media only screen and (min-width: 1024px) {
    .modal {
        max-width: 992px;
    }
}

</style>