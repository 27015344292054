<template>

    <loading-spinner v-if="isLoading" />
    <section v-else>
        <notification-list />
        <form v-on:submit.prevent="saveProject()">
            <h1>Create Project</h1>
            <div class="top-flex">
                <header>
                <p class="header-label">Header:</p>
            </header>

            <div>
                <label class="header" for="projectId">Id:</label>
                <div id="projectId">{{ project.id }}</div>
            </div>
            </div>
           
            <div class="form-control">
                <label class="header" for="name">Project Name:</label>
                <input type="text" id="name" size=40 v-model="project.name" autofocus />
            </div>
            <div class="form-control">
                <label id="des-label" class="header" for="description">Description:</label>
                <textarea id="description" rows="8" cols="80" v-model="project.description" />
            </div>
            <div class="sprint-row-flex">

                <div class="form-control">
                <label class="header" for="sprintLength">Sprint Length:</label>
                <select id="sprintLength" name="sprintLength" v-model="project.sprintLength">
                    <option v-for="option in sprintLengths" v-bind:key="option"
                        v-bind:selected="project.sprintLength == option" v-bind:value="option">{{
                            prettify(option) }}</option>
                </select>
            </div>
       
            <div class="form-control" v-if="isNewProject">
                <label class="header" for="templateProject">Template:</label>
                <select id="templateProject" name="templateProject" v-model="project.templateId">
                    <option value="0">None</option>
                    <option v-for="templateProject in projectTemplates" v-bind:key="templateProject.id"
                        v-bind:selected="project.templateId == templateProject.id" v-bind:value="templateProject.id">{{
                            prettify(templateProject.name) }}</option>
                </select>
            </div>
            </div>
            <div class="permission">
            <p id="perm" class="perm">Permission table</p>
            <p class="perm">Invite user</p>
            </div>
            <div class="button-group">
                <button class="btn" type="submit" v-bind:disabled="isProjectModified">Save</button>
            </div>
        </form>
    </section>
</template>
  
<script>
import projectService from "../services/ProjectService";
import LoadingSpinner from "../components/LoadingSpinner";
import NotificationList from "@/components/NotificationList.vue";


export default {
    name: 'edit-card-view',
    components: { LoadingSpinner, NotificationList },
    data() {
        return {
            isLoading: false,
            isNewProject: !this.$route.params.projectId,
            projectId: this.$route.params.projectId,
            project: {},
            savedProject: {},
            projectUsers: [],
            projectTemplates: [],
            sprints: [],
            sprintLengths: ['ONE_WEEK', 'TWO_WEEKS', 'EVERY_CALENDAR_MONTH']
        }
    },
    computed: {
        isProjectModified() {
            return JSON.stringify(this.project) === JSON.stringify(this.savedProject);
        }
    },
    created() {
        if (!this.isNewProject) {
            this.loadProject(this.projectId);
        } else {
            this.loadTemplates();
        }
    },
    methods: {
        prettify(value) {
            return value.replaceAll('_', ' ');
        },
        saveProject() {
            this.isLoading = true;
            if (this.isNewProject) {
                projectService.createProject(this.project).then((response) => {
                    this.project = response.data;
                    this.savedProject = JSON.parse(JSON.stringify(this.project));
                    this.isLoading = false;
                    this.$router.push({ name: 'myProjects'});
                    this.$store.commit('ADD_NOTIFICATION', {type: 'success', message: `Project ${this.project.name} was successfully created`});
                }).catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 400){
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Please fill in Project Name, Description, and Sprint Length"
                        });
                    } else if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    }
                    else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: 'Sorry an error occurred: ' + error,
                        });
                        console.error("Creating project was unsuccessful: ", response?.message);
                    }
                });
            } else {
                projectService.saveProject(this.projectId, this.project).then((response) => {
                    this.project = response.data;
                    this.savedProject = JSON.parse(JSON.stringify(this.project));
                    this.isLoading = false;
                    this.$store.commit('ADD_NOTIFICATION', {type: 'success', message: `Project ${this.project.name} was successfully updated`});
                }).catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    }
                    else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error("Saving project was unsuccessful: ", response?.message);
                    }
                })
            }
        },
        loadProject(projectId) {
            this.isLoading = true;
            projectService.getProjectSettings(projectId)
                .then((response) => {
                    this.project = response.data;
                    this.savedProject = JSON.parse(JSON.stringify(this.project));
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    }
                    else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error("Getting project was unsuccessful: ", response?.message);
                    }
                });
        },
        loadTemplates() {
            this.isLoading = true;
            projectService.getAllTemplates().then((response) => {
                this.projectTemplates = response.data;                
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                let response = error.response;
                if (response?.status === 401) {
                    // Token expired
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Session timeout. Please login again.",
                    });
                    this.$store.commit("LOGOUT");
                    this.$router.push({ name: "login" });
                }
                else {
                    // Something else unexpected happened
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Error getting templates",
                    });
                    console.error("Unable to get templates: ", response?.message);
                }
            })
        }
    },
};
</script>

<style scoped>

.header-label {
    margin-right: 70px;
    font-size: 22px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    display: flex;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 3%;
    padding-right: 3%;
    border: 10px white solid;
    border: 0.5px solid white;
    border-radius: 3px;
    font-weight: 400;
    background-color: rgba(18, 18, 18, 0.75);
    font-size: 17px;
}

.form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-align: left;
    flex-grow: 1;
}


#description {
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 5px;
    color: rgb(255, 34, 0);
    font-family: "MedievalSharp", cursive;
    font-size: 17px;
    width: 400px;
    height: 150px;
}

#description:focus {
    border: solid 4px rgb(202, 3, 3);
}

#des-label {
    margin-right: 18px;
}

#sprintLength {
    font-family: "MedievalSharp", cursive;
    background-color: rgba(18, 18, 18, 0.75);
    color: white;
    border: 0.5px solid white;
    border-radius: 3px;
    height: 25px;
}

#sprintLength:focus {
    border: solid 3px rgb(202, 3, 3);
}

#templateProject {
    font-family: "MedievalSharp", cursive;
    background-color: rgba(18, 18, 18, 0.75);
    color: white;
    border: 0.5px solid white;
    border-radius: 3px;
    height: 25px;
}

#templateProject:focus {
    border: solid 3px rgb(202, 3, 3);
}

h1 {
    margin-bottom: 2%;
    margin-top: 3%;
}

#logo {
    width: 30%;
}

.header {
    font-size: 22px;
}

.btn {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 5px;
  margin-bottom: 2%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

.button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.top-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.sprint-row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 107%;
}

.permission {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#perm {
    margin-right: 30px;
}

</style>