<template>
  <main class="main-content-container">
    <div class="projects-container">
    <h1 class="page-title">My Projects</h1>
    <img id="logo" alt="Crusader Games logo" src="../assets/logo.png">
    <notification-list />
    <loading-spinner v-if="isLoading" />
    <project-table v-on:clickEvent="handleLeaveEvent" v-on:deleteEvent="handleDeleteEvent" v-bind:projects="projects" />
    </div>
    <button class="btn" v-on:click="goToProjectForm()">Create New Project</button>
  </main>
</template>

<script>
import projectService from "../services/ProjectService";
import ProjectTable from "../components/ProjectTable.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NotificationList from "../components/NotificationList.vue";
import dialog from '@/dialog.js'

export default {
  components: { ProjectTable, LoadingSpinner, NotificationList },
  created() {
    this.loadProjects();
  },
  data() {
    return {
      isLoading: true,
      projects: [],
      //   showAddEditForm: false
    };
  },
  methods: {
    async handleDeleteEvent(project) {
      const confirmed = await dialog.okText('Yes').cancelText('No').confirm(`Are you sure you want to delete ${project.name}?`);
      if (confirmed) {
        this.deleteProject(project);
      }
    },
    deleteProject(project) {
      this.isLoading = true;
      projectService.deleteProject(project.id).then(() => {
        this.$store.commit("ADD_NOTIFICATION", {
          type: "success",
          message: `You deleted '${project.name}'`,
        });
        const index = this.projects.indexOf(project);
        if (index > -1) {
          this.projects.splice(index, 1);
        }
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        let response = error.response;
        if (response?.status === 401) {
          // Token expired
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message: "Session timeout. Please login again.",
          });
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "login" });
        } else {
          // Something else unexpected happened
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message:
              "Sorry, something unexpected occurred. Please try again later.",
          });
          console.error(
            "Deleting project was unsuccessful: ",
            response?.message
          );
        }
      });
    },
    handleLeaveEvent(project) {
      this.isLoading = true;
      projectService.leaveProject(project.id).then(() => {
        this.$store.commit("ADD_NOTIFICATION", {
          type: "success",
          message: `You left project '${project.name}'`,
        });
        const index = this.projects.indexOf(project);
        if (index > -1) {
          this.projects.splice(index, 1);
        }
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        let response = error.response;
        if (response?.status === 401) {
          // Token expired
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message: "Session timeout. Please login again.",
          });
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "login" });
        } else {
          // Something else unexpected happened
          this.$store.commit("ADD_NOTIFICATION", {
            type: "error",
            message:
              "Sorry, something unexpected occurred. Please try again later.",
          });
          console.error(
            "Leaving project was unsuccessful: ",
            response?.message
          );
        }
      });
    },
    loadProjects() {
      this.isLoading = true;
      projectService
        .getMyProjects()
        .then((response) => {
          this.projects = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else {
            // Something else unexpected happened
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message:
                "Sorry, something unexpected occurred. Please try again later.",
            });
            console.error(
              "Getting projects was unsuccessful: ",
              response?.message
            );
          }
        });
    },
    goToProjectForm() {
      // Go back to the home/landing page, using "/" incase what that is changes.
      this.$router.push("/projects/create");
    }
  }
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: row;
}

.btn {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 1%;
  margin-bottom: 5%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 5%;
  padding-top: 5%;
  padding-left: 1%;
  padding-right: 1%;
  border: 10px white solid;
  border: 0.5px solid white;
  border-radius: 3px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  background-color: rgba(18, 18, 18, 0.75);
  font-size: 17px;
}

.page-title {
  font-family: "MedievalSharp", cursive;
  padding-bottom: 5%;
}

#logo {
  margin-bottom: 4%;
}
</style>