<template>
  <div id="view-login" class="main-content-container ">
    <h1 class="page-title">Login</h1>
    <img alt="Crusader Games logo" src="../assets/logo.png">
    <notification-list />
    <login-form />
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import NotificationList from '../components/NotificationList.vue';

export default {
  components: {LoginForm, NotificationList},
};
</script>

<style scoped>
#view-login {
 height: 120%;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 5%;
 margin-bottom: 5%;
 margin-left: 10%;
 margin-right: 10%;
 border: 0.5px solid white;
 border-radius: 3px;
 background-color: rgba(18, 18, 18, 0.75);
}

.page-title {
  margin-bottom: 3%;
}

</style>
