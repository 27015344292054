<template>  
  <div id="view-register">  
    <h1 class="page-title">Register</h1>
    <img id="logo" alt="Crusader Games logo" src="../assets/logo.png">
    <notification-list />
    <register-user-form />
  </div>
</template>

<script>
import RegisterUserForm from '../components/RegisterUserForm.vue';
import NotificationList from '../components/NotificationList.vue';

export default {
  components: {RegisterUserForm, NotificationList},
};
</script>

<style scoped>


.page-title {
  padding-top: 4%;
  margin-bottom: 2%;
}

#view-register {
 border: 5px white solid;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-top: 5%;  
 margin-bottom: 5%;
 margin-left: 10%;
 margin-right: 10%;
 border: 0.5px solid white;
 border-radius: 3px;
 background-color: rgba(18, 18, 18, 0.75);
}


</style>
