<template>
    <main>
        <div class="top-container">
            <sprint-nav v-bind:projectId="projectId" class="nav"/>
            <h1 class="title">{{ isCardNew ? "Create" : "Edit" }} Card</h1>
            <notification-list />
        </div>
        <loading-spinner v-if="isLoading" />
        <form class="card-form" v-on:submit.prevent="isCardNew ? createCard() : saveCard()">
            <header class="card-header" v-bind:class="card.cardType?.toLowerCase()">
        
                <div class="header-row">
                    <font-awesome-icon class="img-icon" v-bind:class="card.cardType?.toLowerCase() + '-color'"
                        v-bind:icon="$store.getFontAwesomeIcon(card.cardType)" />
                    <h2>{{ card.cardType }} {{ card.id }}: {{ card.name }}</h2>
                </div>
                <div class="header-row spacing">
                    <div class="header-flex">
                        <label class="header-label" for="developer1">Developer 1:</label>
                        <select-card-user class="select-drop" v-model="card.assignedId" v-bind:users="projectUsers"
                            v-bind:selectedUser="card.assigned"></select-card-user>
                    </div>
                    <div class="header-flex">
                        <label class="header-label" for="developer2">Developer 2:</label>
                        <select-card-user class="select-drop" v-model="card.partnerId" v-bind:users="projectUsers"
                            v-bind:selectedUser="card.partner"></select-card-user>
                    </div>
                    <div class="header-flex">
                        <label class="header-label" for="tester">Tester:</label>
                        <select-card-user class="select-drop" v-model="card.testerId" v-bind:users="projectUsers"
                            v-bind:selectedUser="card.tester"></select-card-user>
                    </div>
                </div>
            </header>
            <section class="spacing status-bar">
                <div class="status-flex">
                    <label class="status-label" for="type">Type: </label>
                    <select class="status-select" id="type" name="type" v-model="card.cardType">
                        <option v-for="option in cardTypes" v-bind:key="option" v-bind:selected="card.cardType == option">{{
                            option }}</option>
                    </select>
                </div>
                <div class="status-flex">
                    <label class="status-label" for="cardState">State: </label>
                    <select class="status-select" id="cardState" name="cardState" v-model="card.cardState">
                        <option v-for="option in cardStates" v-bind:key="option" v-bind:selected="card.cardState == option">
                            {{ option }}</option>
                    </select>
                </div>
                <div class="status-flex">
                    <label class="status-label" for="sprint">Sprint: </label>
                    <select-sprint class="status-select" v-model="card.sprint" v-bind:sprints="sprints"></select-sprint>
                </div>


                <button class="save-button" type="submit" v-bind:disabled="isCardModified">
                    <font-awesome-icon class="img-icon" icon="fa-floppy-disk" />
                        Save Card</button>

            </section>

            <section class="main-content">
                <h3>Content</h3>
                <div class="form-control">
                    <label for="name">Display Name:</label>
                    <input class="text-input" type="text" id="name" v-model="card.name" size="120" autofocus />
                </div>
                <div class="form-control">
                    <label for="description">Description:</label>
                    <textarea class="text-input" id="description" rows="8" cols="80" v-model="card.description" />
                </div>
                <div v-if="card.cardType === 'STORY'" class="form-control">
                    <label for="acceptanceCriteria">Acceptance Criteria:</label>
                    <textarea class="text-input" id="acceptanceCriteria" rows="8" cols="80"
                        v-model="card.acceptanceCriteria" />
                </div>
            </section>

            <section class="planning">
                <h3>Planning</h3>
                <div v-if="card.cardType === 'STORY' || card.cardType === 'DEFECT'" class="control">
                    <label for="storyPoints">Story Points</label>
                    <select-story-points id="storyPoints" v-model="card.storyPoints"></select-story-points>
                </div>
            </section>

            <section class="related">
                <h3>Related</h3>
                <div class="control">
                    <label for="parentId">Parent Id</label>
                    <input id="parentId" type="number" min="0" v-model="card.parentId" />
                </div>
            </section>
        </form>
    </main>
</template>
  
<script>
import projectService from "../services/ProjectService";
import LoadingSpinner from "../components/LoadingSpinner";
import SelectCardUser from "../components/SelectCardUser";
import SelectSprint from "@/components/SelectSprint";
import SelectStoryPoints from "@/components/SelectStoryPoints";
import NotificationList from "@/components/NotificationList.vue";
import SprintNav from "@/components/SprintNav.vue";

export default {
    name: 'edit-card-view',
    components: { LoadingSpinner, SelectCardUser, SelectSprint, SelectStoryPoints, NotificationList, SprintNav },
    created() {
        if (!this.isCardNew) {
            this.loadCard(this.projectId, this.cardId);
        } else {
            this.card.projectId = this.projectId;
            this.card.cardState = "NEW";
        }
    },
    data() {
        return {
            isLoading: false,
            cardId: this.$route.params.cardId,
            projectId: this.$route.params.projectId,
            card: {},
            savedCard: {},
            cardTypes: ["EPIC", "STORY", "DEFECT", "BUG", "TASK"],
            cardStates: ["NEW"],
            projectUsers: [],
            sprints: []
        }
    },
    computed: {
        isCardModified() {
            console.log(JSON.stringify(this.card), "||", JSON.stringify(this.savedCard));
            return JSON.stringify(this.card) === JSON.stringify(this.savedCard);
        },
        isCardNew() {
            return !this.cardId;
        }
    },
    methods: {
        getName(user) {
            return user.displayName ? user.displayName : user.username;
        },
        createCard() {
            this.isLoading = true;
            projectService.createCard(this.projectId, this.card).then((response) => {
                const cardDisplay = response.data;
                this.card = cardDisplay.card;
                this.savedCard = JSON.parse(JSON.stringify(cardDisplay.card));
                this.cardTypes = cardDisplay.cardTypes;
                this.cardStates = cardDisplay.cardStates;
                this.projectUsers = cardDisplay.projectUsers;
                this.sprints = cardDisplay.projectSprints;
                this.isLoading = false;
                this.$router.push({ name: "product-backlog" });
                this.$store.commit("ADD_NOTIFICATION", {
                    type: "success",
                    message: `Card '${this.card.name}' successfully created`,
                });
            }).catch((error) => {
                this.isLoading = false;
                let response = error.response;
                if (response?.status === 401) {
                    // Token expired
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Session timeout. Please login again.",
                    });
                    this.$store.commit("LOGOUT");
                    this.$router.push({ name: "login" });
                }
                else {
                    // Something else unexpected happened
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Sorry, something unexpected occurred. Please try again later.",
                    });
                    console.error("Saving card was unsuccessful: ", response?.message);
                }
            })
        },
        saveCard() {
            this.isLoading = true;
            projectService.saveCard(this.projectId, this.cardId, this.card).then((response) => {
                const cardDisplay = response.data;
                this.card = cardDisplay.card;
                this.savedCard = JSON.parse(JSON.stringify(cardDisplay.card));
                this.cardTypes = cardDisplay.cardTypes;
                this.cardStates = cardDisplay.cardStates;
                this.projectUsers = cardDisplay.projectUsers;
                this.sprints = cardDisplay.projectSprints;
                this.isLoading = false;
                this.$store.commit("ADD_NOTIFICATION", {
                    type: "success",
                    message: "Card was updated.",
                });
            }).catch((error) => {
                this.isLoading = false;
                let response = error.response;
                if (response?.status === 401) {
                    // Token expired
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Session timeout. Please login again.",
                    });
                    this.$store.commit("LOGOUT");
                    this.$router.push({ name: "login" });
                }
                else {
                    // Something else unexpected happened
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Sorry, something unexpected occurred. Please try again later.",
                    });
                    console.error("Saving card was unsuccessful: ", response?.message);
                }
            })
        },
        loadCard(projectId, cardId) {
            this.isLoading = true;
            projectService.getCard(projectId, cardId)
                .then((response) => {
                    const cardDisplay = response.data;
                    this.card = cardDisplay.card;
                    // JavaScript hack to clone an object.
                    this.savedCard = JSON.parse(JSON.stringify(cardDisplay.card));
                    this.cardTypes = cardDisplay.cardTypes;
                    this.cardStates = cardDisplay.cardStates;
                    this.projectUsers = cardDisplay.projectUsers;
                    this.sprints = cardDisplay.projectSprints;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    }
                    else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error("Getting card was unsuccessful: ", response?.message);
                    }
                });
        },
    },
};
</script>

<style scoped>

main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-container {
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    align-self: center;
    margin-top: 2%;
    background-color: rgba(18, 18, 18, 0.75);
}

.img-icon {
    height: 20px;
    padding-left: 1%;
    padding-right: 1%;
}

.title {
    margin-top: 4%;
    margin-bottom: 3%;
}

.nav {
    margin-top: 3%;
}

.card-form {
    border: 1px solid white;
    margin-top: 3%;
    margin-bottom: 2%;
    background-color: rgba(18, 18, 18, 0.75);
    max-width: 850px;
}

.card-form {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "header header header"
        "status status status"
        "main planning related";
}

.card-header {
    grid-area: header;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    gap: .2rem;
    width: 100%;
    padding-top: 2%;
} 

.status-bar {
    grid-area: status;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-content {
    grid-area: main;
    align-items: flex-start;
}

.planning {
    grid-area: planning;
}

.related {
    grid-area: related;
    display: flex;
    align-items: center;
}

.save-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .2rem;
    align-items: center;
    padding: 5px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    width: 20%;
}

.save-button:hover {
    color: rgb(202, 3, 3);
}

.header-row {
    display: flex;
    flex-direction: row;
    gap: .2rem;
    align-items: center;
}

.form-control {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-align: left;
}

.control {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.control label {
    border-bottom: 1px solid;
}

.form-control label {
    border-bottom: 1px solid;
}

.text-input {
    max-width: 515px;
}

.header-flex {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1%;
}

.select-drop {
    display: flex;
    align-items: center;
    padding-left: 5%;
    max-width: 150px;
}

.header-label {
    font-size: large;
}

.status-label {
    font-size: large;
}

.status-select {
    background-color: rgba(18, 18, 18, 0.75); 
    border: .5px solid white;
    border-radius: 4px;
    color: white;
    padding: 3.5%;
}

.status-select:hover {
    cursor: pointer;
    filter: brightness(85%);
    transition: ease-in-out 0.3s;
    color: rgb(202, 3, 3);
}

.status-flex {
    display: flex;
    gap: 6%;
    align-items: center;
}

.spacing {
    justify-content: space-between;
}

#description {
    background-color: transparent;
    border: .5px solid white;
    border-radius: 5px;
    color: rgb(255, 34, 0);
    padding-top: 1%;
    padding-left: 1%;
}

#description:focus {
   border: solid 5px rgb(202, 3, 3);
}

#acceptanceCriteria {
    background-color: transparent;
    border: .5px solid white;
    border-radius: 5px;
    color: rgb(255, 34, 0);
    padding-top: 1%;
    padding-left: 1%;
}

#acceptanceCriteria:focus {
    border: solid 5px rgb(202, 3, 3);
}

#parentId {
    width: 60%;
}

#storyPoints {
    background-color: rgba(18, 18, 18, 0.75); 
    border: .5px solid white;
    border-radius: 4px;
    color: white;
    width: 100%;
    height: 32px;
}

#storyPoints:focus {
    border: solid 5px rgb(202, 3, 3);
}
</style>