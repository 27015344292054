<template>
    <section>
        <div class="header">
            <sprint-nav v-bind:projectId="projectId" v-bind:iteration="iteration"></sprint-nav>
            <h1>Sprint Taskboard</h1>
        </div>
        <notification-list />
        <loading-spinner v-if="isLoading" />
        <BaseModal v-if="isModalVisible" @close="isModalVisible = false">
            <template v-slot:body>
                <card-form v-bind:cardInput="cardInput" v-on:saveCard="createCard"></card-form>
            </template>
        </BaseModal>
        <table v-if="!isDisabled && !isLoading" id="taskboard">
            <thead>
                <th>Stories</th>
                <th>New</th>
                <th>Active</th>
                <th>Resolved</th>
                <th class="right-border">Closed</th>
            </thead>
            <tbody>
                <tr class="taskboard-row right-border left-border">
                    <td class="story-cell">
                        <mini-card class="stories"></mini-card>
                        <div class="button-row">
                            <button v-on:click="openModal('TASK', 0)">
                                <font-awesome-icon class="img-icon task-color"
                                    v-bind:icon="$store.getFontAwesomeIcon('TASK')" />
                                Add
                            </button>
                        </div>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, 0, 'NEW')" v-on:dragenter.prevent v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="card in filterCards(orphanTasks, 'NEW')" v-bind:card="card"
                            v-bind:users="projectUsers" v-bind:key="card.id" v-on:dragstart="startDrag($event, card)"
                            v-on:userSelect="onUserSelect($event, card.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, 0, 'ACTIVE')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="card in filterCards(orphanTasks, 'ACTIVE')" v-bind:card="card"
                            v-bind:users="projectUsers" v-bind:key="card.id" v-on:dragstart="startDrag($event, card)"
                            v-on:userSelect="onUserSelect($event, card.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, 0, 'RESOLVED')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="card in filterCards(orphanTasks, 'RESOLVED')" v-bind:card="card"
                            v-bind:users="projectUsers" v-bind:key="card.id" v-on:dragstart="startDrag($event, card)"
                            v-on:userSelect="onUserSelect($event, card.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, 0, 'CLOSED')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="card in filterCards(orphanTasks, 'CLOSED')" v-bind:card="card"
                            v-bind:users="projectUsers" v-bind:key="card.id" v-on:dragstart="startDrag($event, card)"
                            v-on:userSelect="onUserSelect($event, card.id)"></mini-card>
                    </td>
                </tr>
                <tr class="taskboard-row right-border left-border" v-for="card in stories" v-bind:key="card.id">
                    <td class="story-cell"><mini-card v-bind:card="card" v-bind:users="projectUsers"
                            v-on:userSelect="onUserSelect($event, card.id)"></mini-card>
                        <div class="button-row">
                            <button v-on:click="openModal('TASK', card.id)">
                                <font-awesome-icon class="img-icon task-color"
                                    v-bind:icon="$store.getFontAwesomeIcon('TASK')" />
                                Add
                            </button>
                            <button v-on:click="openModal('BUG', card.id)">
                                <font-awesome-icon class="img-icon bug-color"
                                    v-bind:icon="$store.getFontAwesomeIcon('BUG')" />
                                Add
                            </button>
                        </div>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, card.id, 'NEW')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="task in filterCards(card.children, 'NEW')" v-bind:card="task"
                            v-bind:users="projectUsers" v-bind:key="task.id" v-on:dragstart="startDrag($event, task)"
                            v-on:userSelect="onUserSelect($event, task.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, card.id, 'ACTIVE')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="task in filterCards(card.children, 'ACTIVE')" v-bind:card="task"
                            v-bind:users="projectUsers" v-bind:key="task.id" v-on:dragstart="startDrag($event, task)"
                            v-on:userSelect="onUserSelect($event, task.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, card.id, 'RESOLVED')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="task in filterCards(card.children, 'RESOLVED')"
                            v-bind:users="projectUsers" v-bind:card="task" v-bind:key="task.id"
                            v-on:dragstart="startDrag($event, task)"
                            v-on:userSelect="onUserSelect($event, task.id)"></mini-card>
                    </td>
                    <td class="task-cell" v-on:drop="onDrop($event, card.id, 'CLOSED')" v-on:dragenter.prevent
                        v-on:dragover.prevent>
                        <mini-card draggable="true" v-for="task in filterCards(card.children, 'CLOSED')" v-bind:card="task"
                            v-bind:users="projectUsers" v-bind:key="task.id" v-on:dragstart="startDrag($event, task)"
                            v-on:userSelect="onUserSelect($event, task.id)"></mini-card>
                    </td>
                </tr>

            </tbody>

        </table>
        <div v-if="isDisabled" class="no-items">
            <img class="no-items-image" src="../assets/pylons.png" />
            <h2>You must construct additional pylons</h2>
            <p>This sprint does not exist. Please select a sprint from the
                <router-link v-bind:to="{ name: 'sprints', params: { projectId: projectId } }">
                    Sprints
                </router-link> page.
            </p>
        </div>
    </section>
</template>

<script>
import projectService from "../services/ProjectService";
import MiniCard from "../components/MiniCard.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import SprintNav from "@/components/SprintNav.vue";
import NotificationList from "@/components/NotificationList.vue";
import CardForm from "@/components/CardForm.vue";
import BaseModal from "@/components/BaseModal.vue";

export default {
    name: 'sprint-taskboard',
    components: { LoadingSpinner, MiniCard, SprintNav, NotificationList, BaseModal, CardForm },
    created() {
        this.loadCards(this.projectId, this.iteration);
    },
    data() {
        return {
            isModalVisible: false,
            isDisabled: false,
            isLoading: true,
            projectUsers: [],
            cardTypes: [],
            cardStates: [],
            projectSprints: [],
            stories: [],
            orphanTasks: [],
            taskMap: new Map(),
            storyMap: new Map(),
            projectId: this.$route.params.projectId,
            iteration: this.$route.params.iteration
        };
    },
    methods: {
        openModal(cardType, parentId) {
            this.cardInput = {
                cardType: cardType,
                projectId: this.projectId,
                cardState: 'NEW',
                parentId: parentId,
                sprint: this.iteration
            }
            this.isModalVisible = true;
        },
        createCard(card) {
            projectService.createCard(this.projectId, card).then((response) => {
                const cardDisplay = response.data;
                const savedCard = cardDisplay.card;
                if (savedCard.parentId) {
                    const story = this.storyMap.get(savedCard.parentId);
                    story.children.push(savedCard);
                } else {
                    this.orphanTasks.push(savedCard);
                }
                this.taskMap.set(savedCard.id, savedCard);
                this.$store.commit("ADD_NOTIFICATION", {
                    type: "success",
                    message: "Card was successfully created",
                });
                this.isModalVisible = false;
            }).catch((error) => {
                console.error(JSON.stringify(error));
                console.error(error);
                let response = error.response;
                if (response?.status === 401) {
                    // Token expired
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Session timeout. Please login again.",
                    });
                    this.$store.commit("LOGOUT");
                    this.$router.push({ name: "login" });
                }
                else {
                    // Something else unexpected happened
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "error",
                        message: "Sorry, something unexpected occurred. Please try again later.",
                    });
                    console.error("Creating card was unsuccessful: ", response?.message);
                }
            });
        },
        filterCards(miniCards, cardState) {
            return miniCards.filter(c => c.cardState == cardState);
        },
        loadCards(projectId, iteration) {
            this.isLoading = true;
            projectService.getSprintTaskboard(projectId, iteration)
                .then((response) => {
                    const taskboard = response.data;
                    this.stories = taskboard.stories;
                    console.log('stories: ', this.stories)
                    this.orphanTasks = taskboard.orphanTasks;
                    this.projectUsers = taskboard.projectUsers;
                    this.cardTypes = taskboard.cardTypes;
                    this.cardStates = taskboard.cardStates;
                    this.projectSprints = taskboard.projectSprints;
                    this.taskMap = new Map();
                    this.orphanTasks.forEach(task => {
                        this.taskMap.set(task.id, task);
                    });
                    this.stories.forEach(story => {
                        this.storyMap.set(story.id, story);
                        story.children.forEach(task => {
                            this.taskMap.set(task.id, task);
                        });
                    })
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    } else if (response?.status === 404) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "This sprint does not exist",
                        });
                        this.isDisabled = true;
                    } else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error("Getting cards was unsuccessful: ", response?.message);
                    }
                });
        },
        startDrag(event, card) {
            console.log(event);
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('cardId', card.id);
        },
        onUserSelect(event, cardId) {
            const userType = event.userType;
            const selectedUserId = event.selectedId;
            const selectedUser = this.projectUsers.find(user => user.id == selectedUserId);

            let card = this.storyMap.get(cardId);
            if (!card) {
                card = this.taskMap.get(cardId);
            }

            const cardOriginal = {
                assigned: card.assigned,
                assignedId: card.assignedId,
                partner: card.partner,
                partnerId: card.partnerId,
                tester: card.tester,
                testerId: card.testerId
            }

            let body = {};
            switch(userType){
                    case 'assigned': {
                        body.assigned = selectedUser;
                        body.assignedId = selectedUserId;
                        break;
                    } case 'partner': {
                        body.partner = selectedUser;
                        body.partnerId = selectedUserId;
                        break;
                    } case 'tester': {
                        body.tester = selectedUser;
                        body.testerId = selectedUserId;
                        break;
                    }
                }
                console.log('body', body)

            projectService.patchCard(card.projectId, card.id, body).then((response) => {
                const updatedCard = response.data.card;
                console.log(JSON.stringify(updatedCard));
                card.assigned = updatedCard.assigned;
                card.assignedId = updatedCard.assignedId;
                card.partner = updatedCard.partner;
                card.partnerId = updatedCard.partnerId;
                card.tester = updatedCard.tester;
                card.testerId = updatedCard.testerId;                
            }).catch((error) => {
                card.assigned = cardOriginal.assigned;
                card.assignedId = cardOriginal.assignedId;
                card.partner = cardOriginal.partner;
                card.partnerId = cardOriginal.partnerId;
                card.tester = cardOriginal.tester;
                card.testerId = cardOriginal.testerId;
                console.error('Error assigning user: ', error);
            });
        },
        onDrop(event, storyId, cardState) {
            const cardId = Number.parseInt(event.dataTransfer.getData('cardId'));
            const card = this.taskMap.get(cardId);

            const originalParentId = card.parentId;
            const originalState = card.cardState;

            if (originalParentId == storyId && card.originalState == cardState) {
                return;
            }

            projectService.patchCard(card.projectId, card.id, { parentId: storyId, cardState: cardState })
                .then(() => {
                    card.parentId = storyId;
                    card.cardState = cardState;

                    if (originalParentId != storyId) {
                        if (originalParentId) {
                            const oldStory = this.storyMap.get(originalParentId);
                            oldStory.children = oldStory.children.filter(c => c.id != card.id);
                        } else {
                            this.orphanTasks = this.orphanTasks.filter(c => c.id != card.id);
                        }

                        if (storyId != 0) {
                            const newStory = this.storyMap.get(storyId);
                            newStory.children.push(card);
                        } else {
                            this.orphanTasks.push(card);
                        }
                    }
                }).catch(error => {
                    card.parentId = originalParentId;
                    card.cardState = originalState;
                    console.error('Error updating card: ', card, error,);
                });
        }
    }
};
</script>

<style scoped>
#taskboard-container {
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fill, 120px);
    grid-row-gap: .5em;
    grid-column-gap: 1em;
}

#taskboard {
    border: 0px none black;
    width: 100%;
}

.story-cell {
    background-color: rgba(18, 18, 18, 0.75);
    width: 1%;
    border-bottom: 1px solid white;
}

.task-cell {
    width: 25%;
    background-color: rgba(18, 18, 18, 0.75);
    max-width: 20vw;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    min-height: 150px;
}

.button-row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

button {
    font-size: 17px;
    background-color: transparent;
    padding: 1.5%;
    border: 0.5px solid white;
    border-radius: 3px;
    color: white;
    font-family: "MedievalSharp", cursive;
}

button:hover {
  color: rgb(202, 3, 3);
}

.no-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.no-items-image {
    width: 20rem;
    height: 20rem;
    border-radius: 20rem;
}

th {
    font-size: large;
    text-align: center;
    background-color: rgba(18, 18, 18, 0.75);
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    border-left: 1px solid white;
}

th:hover {
    color: rgb(202, 3, 3);
}

.right-border {
    border-right: 1px white solid;
}

.left-border {
    border-left: 1px white solid;
}

.stories {
    border-left: 1px solid white;
    margin-bottom: 6%;
}

.header {
    background-color: rgba(18, 18, 18, 0.75);
    padding: 3%;
    border: 1px solid white;
    border-radius: 5px;
}

h1 {
    margin-top: 4%;
}

</style>

