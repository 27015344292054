import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'
import MyProjectsView from '../views/MyProjectsView.vue'
import PublicProjectsView from '../views/PublicProjectsView.vue'
import MyProfileView from '../views/MyProfileView.vue'
import RegisterView from '../views/RegisterView.vue'
import LogoutView from '../views/LogoutView.vue'
import LoginView from '../views/LoginView.vue'
import UserProfileView from '../views/UserProfileView.vue'
import ProductBacklogView from '../views/ProductBacklogView.vue'
import SprintsView from '../views/SprintsView.vue'
import SprintBacklogView from '../views/SprintBacklogView.vue'
import EditCardView from '../views/EditCardView.vue'
import ProjectView from '../views/ProjectView.vue'
import SprintTaskboardView from '../views/SprintTaskboardView.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/profile/:userId',
    name: 'userProfile',
    component: UserProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/profile',
    name: 'myProfile',
    component: MyProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/user',
    name: 'myProjects',
    component: MyProjectsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects',
    name: 'allProjects',
    component: PublicProjectsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/create',
    name: 'create-project',
    component: ProjectView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId',
    name: 'edit-project',
    component: ProjectView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sprints',
    name: 'sprints',
    component: SprintsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/cards',
    name: 'product-backlog',
    component: ProductBacklogView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sprints/:iteration',
    name: 'sprint-backlog',
    component: SprintBacklogView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/sprints/:iteration/taskboard',
    name: 'taskboard',
    component: SprintTaskboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/cards/edit/:cardId',
    name: 'edit-card-view',
    component: EditCardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*
 * The beforeEach method runs before each route. It is a feature of Vue Router 
 * called a Navigation Guard (https://router.vuejs.org/guide/advanced/navigation-guards.html). 
 * 
 * This method checks to see if the route requires authentication as defined in the routes 
 * setup using the meta object key `requiresAuth`. If auth is required and the user is not 
 * logged in, it will redirect to the login view. Otherwise it allows them to go through to
 * their destination route. 
 * 
 * It will also clear error notifications, but not success notifications. 
 */
router.beforeEach((to) => {

  // Get the Vuex store
  const store = useStore();

  // Clear error notifications from previous view
  store.commit("CLEAR_ERROR_NOTIFICATIONS");
  
  // Determine if the route requires Authentication
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  // If it does and they are not logged in, send the user to "/login"
  if (requiresAuth && store.state.token === '') {
    return {name: "login"};
  } 
  // Otherwise, do nothing and they'll go to their next destination
});

export default router
