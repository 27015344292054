<template>
    <nav class="nav-list" v-if="this.$store.state.user.id">
        <div class="nav-link"><router-link to="/">Home</router-link></div>
        <div class="nav-link"><router-link v-bind:to="{ name: 'myProjects' }">My Projects</router-link></div>
        <div class="nav-link"><router-link v-bind:to="{ name: 'allProjects' }">Public Projects</router-link></div>
        <div class="nav-link"><router-link to="/about">About Us</router-link></div>
        
    </nav>
</template>
  
<style scoped>
.nav-link .router-link-exact-active {
    color: rgb(202, 3, 3)
}

.nav-list {
  background-color: rgba(18, 18, 18);
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
}

</style>