<template>
  <form id="form-login" v-on:submit.prevent="login">
    <div class="form-control">
      <label class="login-text" for="username">Username:</label>
      <input type="text" id="username" size=50 v-model="user.username" required autofocus autocomplete="username" />
    </div>
    <div class="form-control">
      <label class="login-text" for="password">Password:</label>
      <input type="password" id="password" size=50 v-model="user.password" required />
    </div>
    <div class="register-button-flex">
      <button type="submit" class="btn">Sign in</button>
      <router-link v-bind:to="{ name: 'register' }" class="new-route">New? Register here!</router-link>
    </div>
  </form>
</template>

<script>
import userService from "../services/UserService";

export default {
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      // Clear any existing error notifications
      this.$store.commit("CLEAR_ERROR_NOTIFICATIONS");

      userService
        .login(this.user)
        .then(response => {
          if (response.status == 200) {
            this.$store.commit('SET_AUTH_TOKEN', response.data.token);
            this.$store.commit('SET_USER', response.data.user);
            this.$router.push({name:'myProjects'});
          }
        })
        .catch(error => {
          let response = error.response;
          if (response?.status === 401) {
            // Login failed
            this.$store.commit("ADD_NOTIFICATION", {type: 'error', message: 'Username or password are incorrect.'});
          } else {
            // Something else unexpected happened
            this.$store.commit("ADD_NOTIFICATION", {type: 'error', message: 'Sorry, something unexpected occurred. Please try again later.'});
          }
        });
    }
  }
}
</script>

<style scoped>
form {
  margin-bottom: 1rem;
  max-width: 40rem;
}

label {
  text-align: left;
  display: inline-block;
  margin-right: 15px;
  width: 150px;
}

input {
  display: inline-block;
}

button {
  margin-left: 165px;
  margin-right: 15px;
  padding: 0.25rem 0.5rem;
}

.form-control {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: large;
}

.register-button-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.btn {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 1%;
  margin-bottom: 2%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

.new-route {
  color: rgb(202, 3, 3);
  font-size: 18px;
}

.login-text {
 font-size: 25px;
}

#username:focus {
  border: solid 4px rgb(202, 3, 3);
}

#password:focus {
  border: solid 4px rgb(202, 3, 3);
}

</style>