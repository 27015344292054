<template>
  <div class="class-row">
    <img v-show="!hasImage" class="avatar" v-bind:src="require('@/assets/not-assigned.svg')" />
    <img v-if="hasImage" class="avatar" v-bind:src="userSelection.profileImageUrl" />
    <!-- Emit is used by v-onModel -->
    <select class="select" v-on:input="handleInput" v-bind:name="name">
      <option value="0">
        Not Assigned
      </option>
      <option v-for="user in users" v-bind:key="user.id" v-bind:selected="user.id === selectedUser?.id"
        v-bind:value="user.id">
        {{ getDisplayName(user) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "select-card-user",
  props: ['users', 'selectedUser', 'name'],
  data() {
    return {
      userSelection: this.selectedUser
    }
  },
  methods: {
    getDisplayName(user) {
      return user?.displayName ? user.displayName : user.username;
    },
    handleInput(event) {
      const selectedId = parseInt(event.target.value);
      this.userSelection = this.users.find((user) => user.id == selectedId);
      this.$emit('update:modelValue', selectedId);
    }
  },
  computed: {
    hasImage() {
      return !!this.userSelection?.profileImageUrl;
    }
  }
};
</script>

<style>
.avatar {
  border-radius: 20px;
  height: 20px;
}

.class-row {
  display: flex;
  gap: .3rem;
}

.select {
  background-color:  rgba(18, 18, 18, 0.75);
  color: white;
  padding: 4px;
  border: 0.5px solid white;
  border-radius: 6px;
  height: 35px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 80%;
}

.select:hover {
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
  color: rgb(202, 3, 3);
}

</style>