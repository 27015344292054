<template>
  <footer id="app-footer">
    <div>&copy; 2023 Crusader Games LLC</div>
    <div>
      <router-link to="/about" class="link">About</router-link>
      &nbsp;|&nbsp;
      <a href="mailto:support@example.com" class="contact">Contact Us</a>
    </div>
  </footer>
</template>

<style scoped>
#app-footer {
  background-image: linear-gradient(rgb(140, 0, 0), rgb(22, 18, 8));
}

.link {
  color: rgb(199, 199, 199);
}

.link:hover {
  color: red;
}

.contact {
  color: rgb(199, 199, 199);
}

.contact:hover {
  color: blue;
}

</style>
