<template>
    <form v-on:submit.prevent="submitPassword()">        
        <div class="errorMessage">{{  errorMessage }}</div>    
        <div class="form-control"> 
            <label for="oldPassword">Current Password:</label>
            <input id="oldPassword" type="password" v-model="oldPassword"/>
        </div>
        <div class="form-control">
            <label for="newPassword1">New Password:</label>
            <input id="newPassword1" type="password" v-model="newPassword1"/>
        </div>
        <div class="form-control">
            <label for="newPassword1">Password Confirm:</label>
            <input id="newPassword2" type="password" v-model="newPassword2"/>
        </div>
        <div class="button-group">
            <button type="submit">Change Password</button>
        </div>
       
    </form>
</template>

<script>
export default {
    name: 'user-password-form',
    data() {
        return {
            oldPassword: '',
            newPassword1: '',
            newPassword2: '',
            errorMessage: '',
        }
    },
    methods: {
        submitPassword(){
            this.$store.commit("CLEAR_ERROR_NOTIFICATIONS");
            this.errorMessage = '';
            if (!this.oldPassword || !this.newPassword1 || !this.newPassword2){
                this.errorMessage = "Please type in password";
                return;
            }
            if (this.newPassword1 !== this.newPassword2){
                this.errorMessage = "New passwords don't match."
                return;
            }
            if (this.newPassword1.length < 6){
                this.errorMessage = "New password must be at least 6 characters."
                return;
            }
            this.$emit('passwordChange', { oldPassword: this.oldPassword, newPassword: this.newPassword1});
        }
    }
}
</script>

<style scoped>
.form-control {
  display: flex;
  margin-bottom: 1rem;
  align-items: baseline;
  gap: 0.5rem;
}

main {
  display: grid;
  
}

form {
  margin-bottom: 1rem;
  
}

label {
  text-align: right;
  display: inline-block;
  margin-right: 15px;
  width: 150px;
}

input {
  display: inline-block;
}

button {
  margin-left: 130px;
  margin-right: 15px;
}

.errorMessage {
    color: red;
    height: 1em;
}

</style>