<template>
  <div class="table">
    <div class="table-row" v-for="project in projects" v-bind:key="project.id">
      <div class="col id">{{ project.id }}</div>
      <div class="col project-name">{{project.name}}</div>
      <div class="col limit">{{ project.description }}</div>
      <div class="col small">
        <router-link
        class="btn" id="backlog" v-bind:to="{ name: 'product-backlog', params: { projectId: project.id } }"
        >
          Backlog
        </router-link>
      </div>
      <div class="col small">
        <router-link
          class="btn" id="sprints" v-bind:to="{ name: 'sprints', params: { projectId: project.id } }"
        >
          Sprints
        </router-link>
      </div>
      <div class="col small edit">
        <router-link
         class="btn"
          v-bind:to="{
            name: 'edit-project',
            params: { projectId: project.id },
          }"
        >
          Edit
        </router-link>
      </div>
      <div class="col small edit">
        <button class="btn size" v-on:click="handleClick(project)">
          {{ publicView ? "Join" : "Leave" }}
        </button>
      </div>
      <div class="col small margin edit">
        <button class="btn size" v-if="isOwner(project)" v-on:click="handleDeleteRequest(project)">
          <!-- <font-awesome-icon class="img-icon" icon="fa-trash-can" /> -->
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-table",
  props: ["projects", "publicView"],

  methods: {
    isOwner(project) {
      return project.ownerId == this.$store.state.user.id;
    },
    handleClick(project) {
      this.$emit('clickEvent', project);
    },
    handleDeleteRequest(project) {
      this.$emit('deleteEvent', project);
    }
  },
};
</script>

<style scoped>

.btn {
  border: 0.2px solid white;
  background-color: transparent;
  color: white;
  padding: 8px;
  font-size: 15px;
  text-decoration: none;
  font-family: "MedievalSharp", cursive;
  border-radius: 3px;
  font-weight: 550;
  display: flex;
  justify-content: center;
}

.btn:hover {
  filter: brightness(85%);
  transition: ease-in-out .3s;
  color: rgb(202, 3, 3);
}   

.project-name {
  font-weight: bold;
  flex: 2;
  margin-left: 2%;
}

.project-name:hover {
  color: rgb(202, 3, 3);
}

.id {
  font-weight: bold;
  align-self: center;
}

.id:hover {
  color: rgb(202, 3, 3);
}

.table-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.col {
  flex: 1;
  padding: 0px;
}

.limit {
  flex: 6;
  margin-left: 1%;
  margin-right: 2%;
}

.small {
  flex: 1 1 0;
  margin-right: 2%;
}

.margin {
  margin-right: 2.5%;
}

.edit {
  flex: .5;
}

.size {
  padding-top: 9px;
  padding-bottom: 9.4px;
}
</style>