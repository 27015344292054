<template>
  <div>
    <header class="view-header">
      <h2>User Profile</h2>
    </header>
    <loading-spinner v-if="isUserLoading" />
    <div v-else id="view-content">
      <notification-list />
      <user-profile v-bind:user="user" />
    </div>

  </div>
</template>

<script>
import userService from "../services/UserService";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import UserProfile from "../components/UserProfile.vue";
import NotificationList from "@/components/NotificationList.vue";

export default {
  components: { LoadingSpinner, UserProfile, NotificationList },
  data() {
    return {
      isUserLoading: true,
      isTableLoading: true,
      userId: this.$route.params.userId,
      bookmarks: [],
      user: {},
    };
  },
  created() {
    this.getUserProfile();
  },
  methods: {
    getUserProfile() {
      this.isUserLoading = true;
      userService
        .getUserProfile(this.userId)
        .then((response) => {
          this.user = response.data;
          this.isUserLoading = false;
        })
        .catch((error) => {
          const response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else {
            // Something else unexpected happened
            this.isUserLoading = false;
            let response = error.response;
            console.error(
              `Could not get user profile for user id ${this.userId}.`,
              response.message
            );
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message:
                "Sorry, something unexpected occurred. Please try again later.",
            });
          }
        });
    },

  },
};
</script>