<template>
  <!-- Emit is used by v-onModel -->
  <select v-on:input="$emit('update:modelValue', sanitize($event.target.value))" name="storyPoints">
    <option></option>
    <option v-for="storyPoints in storyPointValues" v-bind:key="storyPoints"
      v-bind:selected="storyPoints == modelValue" v-bind:value="storyPoints">{{ storyPoints }}</option>
  </select>
</template>

<script>
export default {
  name: "select-story-points",
  props: ["modelValue"],
  data() {
    return {
      storyPointValues: [1, 2, 3, 5, 8, 13, 21, 32]
    }
  },
  methods: {
    sanitize(value) {
      if (value) {
        return parseInt(value);
      } else {
        return null;
      }
    }
  }
};
</script>