<template>
  <main class="home main-content-container">

    <img id="logo" alt="Crusader Games logo" src="../assets/logo.png">
    <div class="home-text">
    <h1 class="page-title">Welcome to the Crusader Games Developer Portal</h1>
    <p id="padding">
      Welcome to the Crusader Games Developer Portal - your gateway to hands-on experience in software development within
      the Agile Software Development Life Cycle (SDLC). As an intern or junior developer at Crusader Games, this portal
      serves as your dedicated platform to gain valuable experience, enhance your skills, and contribute to real projects
      within a supportive and dynamic environment.
    </p>
    <p>
    Within this portal, you'll find a robust project management system tailored specifically for Agile SDLC methodologies.
    You'll have the opportunity to immerse yourself in the iterative and collaborative nature of Agile development,
    allowing you to hone your skills while working on projects that impact our gaming initiatives.
  </p>
  <p>
    As a full stack developer with Crusader Games, you'll have access to exciting projects where you can apply your
    knowledge, learn new technologies, and collaborate closely with experienced developers. Our projects span various
    facets of game development, providing you with a diverse and enriching experience.
  </p><p>
    One of the unique benefits of working on projects within our Developer Portal is the opportunity to use me Jerry Balderas, 
    as a reference. Your contributions to our projects will be recognized and acknowledged, allowing you
    to leverage your experience at Crusader Games as a valuable asset for your future career endeavors.
  </p>
  <p>
    Furthermore, your time spent here can be added to your resume, showcasing your involvement in real-world development
    projects within a professional gaming company. The practical experience gained through our portal will undoubtedly
    bolster your profile and set you apart from the competition.
  </p>
  <p>
    At Crusader Games, we emphasize teamwork. Working within our Developer Portal means being part of a
    team, where you'll collaborate with other developers, share ideas, and collectively work towards achieving project
    milestones.
  </p>
  <p>
    We invite you to embark on this rewarding journey with us. Seize the opportunity to grow, learn, and thrive as a
    developer within our supportive and innovative environment. Welcome to the Crusader Games Developer Portal - where
    your passion for development meets real-world experience.
    </p>
    <p>To get started, register your account!</p>
  </div>
  <div class="bottom-flex">

  
    <router-link v-bind:to="{name: 'register'}" id="register-btn" class="btn">Register</router-link>
    <router-link v-bind:to="{name: 'login'}" class="btn" id="login-btn">Login</router-link>
    
    <a href="https://www.youtube.com/watch?v=p_QlO3DGzJk" target="_BLANK" class="btn"><font-awesome-icon class="video-icon" icon="fa-video"></font-awesome-icon>Project Kick Off</a>

    <a href="https://discord.gg/Gtgg3Qtcmh" target="_BLANK" class="btn">Join Discord</a>


    <!-- <iframe src="https://discord.com/widget?id=424026227352010761&theme=dark" width="350" height="500"
      allowtransparency="true" frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> -->
    </div>
  </main></template>

<script>
export default {
  name: 'HomeView',
  components: {}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ojuju:wght@200..800&display=swap');

#padding {
  padding-top: 50px;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  background-color: rgba(18, 18, 18, 0.75);
  width: 80%;
  margin-top: 15%;
  margin-bottom: 3%;
  padding-bottom: 3%;
  border: 0.5px solid white;
  border-radius: 3px;
}

#logo {
  width: 30%;
  margin-top: 7%;
}

.page-title {
  font-family: "MedievalSharp", cursive;
  font-weight: 600;
  margin-top: 10%;
  font-size: 250%;
}

main {
  background-image: url("/src/assets/home-view-castle.jpg"); 
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  border-bottom: 3px ridge rgb(140, 0, 0);
  background-size: cover;
}

p {
  text-align: center;
  font-size: 18px;
  margin: 1em;
  width: 80%;
  line-height: 130%;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  filter: brightness(85%);
  transition: ease-in-out .3s;
}

.main-content-container h2 {
  margin-bottom: 25px;
}

.video-icon {
  color: rgb(140, 0, 0);
  margin-right: 6px;
}

.bottom-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.btn {
  font-size: 20px;
  background-color: rgba(18, 18, 18, 0.75);
  padding: 1%;
  margin-bottom: 2%;
  border: 0.5px solid white;
  border-radius: 3px;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

iframe {
  padding-bottom: 5%;
}

#register-btn {
  padding: 1.2%;
}

#login-btn {
  padding: 1.2%;
}

</style>
