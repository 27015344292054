<template>
  <div>
    <header class="view-header">
      <h1>My Profile</h1>
    </header>
    <div id="view-content">
      <notification-list />
      <edit-profile-form />
      <user-password-form v-on:passwordChange="submitPasswordChange($event)" />
    </div>
  </div>
</template>

<script>
import NotificationList from '../components/NotificationList.vue';
import EditProfileForm from "../components/EditProfileForm.vue"
import UserPasswordForm from "../components/UserPasswordForm.vue";
import userService from '@/services/UserService';

export default {
  components: { EditProfileForm, NotificationList , UserPasswordForm},
  data() {
    return {
      userProfile: {}
    }
  },
  created() {
        // Copy over current user profile values from Vuex Store
        this.userProfile = { ...this.$store.state.user };
    // Remove any role information
    delete this.userProfile.authorities;
  },
  methods: {
    submitPasswordChange(passwordDto) {
      this.isUserLoading = true;
      userService.changePassword(passwordDto)
        .then((response) => {
          this.$store.commit("ADD_NOTIFICATION", {
            type: "success",
              message: "Password has been changed.",
          });
          this.user = response.data;
          this.isUserLoading = false;
        }).catch((error) => {
          const response = error.response;
          if (response?.status === 401) {
            // Token expired
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: "Session timeout. Please login again.",
            });
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "login" });
          } else {
            // Something else unexpected happened
            this.isUserLoading = false;
            let response = error.response;
            console.error(
              `Could not change password for ${this.userProfile.username}.`,
              response.message
            );
            this.$store.commit("ADD_NOTIFICATION", {
              type: "error",
              message: `Could not change password for ${this.userProfile.username}.`,
            });
          }
        });
    }
  }
};
</script>

<style scoped>
#view-content {
  width: 90%;
}
</style>
