<template>
    <section>
    <div class="top-container">
        <sprint-nav />
        <h1 class="title">Product Backlog</h1>
        <h2 class="title">(This is where you assign stories to sprints)</h2>
        <notification-list />
        <div class="button-group">
            <button type="btn" class="btn" @click="showModal('STORY')" v-bind:disabled="isError">
                <font-awesome-icon class="img-icon story-color" v-bind:icon="$store.getFontAwesomeIcon('STORY')" />
                Create Story
            </button>

            <button type="btn" class="btn" @click="showModal('DEFECT')" v-bind:disabled="isError">
                <font-awesome-icon class="img-icon defect-color" v-bind:icon="$store.getFontAwesomeIcon('DEFECT')" />
                Create Defect
            </button>
        </div>
    </div>
    

        <BaseModal v-if="isModalVisible" @close="isModalVisible = false">
            <template v-slot:body>
                <card-form v-bind:cardInput="cardInput" v-on:saveCard="createCard"></card-form>
            </template>
        </BaseModal>
        <loading-spinner v-if="isLoading" />
        <card-table v-if="!isLoading && !isError" v-bind:cards="cards" v-bind:users="users" v-bind:sprints="sprints"
            v-bind:cardTypes="cardTypes" v-bind:cardStates="cardStates" />
        <div v-if="isError" class="no-items">
            <img class="no-items-image" src="../assets/luigi.jpg" />
            <h2>Luigi does not approve.</h2>
            <p>This project does not exist. Please create a project at
                <router-link v-bind:to="{ name: 'myProjects' }">My Projects</router-link> page.
            </p>
        </div>
    </section>
</template>

<script>
import projectService from "../services/ProjectService";
import CardTable from "../components/CardTable";
import LoadingSpinner from "../components/LoadingSpinner";
import NotificationList from "../components/NotificationList.vue";
import SprintNav from "@/components/SprintNav.vue";
import BaseModal from "@/components/BaseModal.vue";
import CardForm from "@/components/CardForm.vue";

export default {
    name: "product-backlog",
    components: {
        CardTable,
        LoadingSpinner,
        NotificationList,
        SprintNav,
        BaseModal,
        CardForm,
    },
    created() {
        this.loadCards(this.projectId);
    },
    data() {
        return {
            isLoading: true,
            isError: false,
            users: [],
            cards: [],
            sprints: [],
            cardTypes: [],
            cardStates: [],
            projectId: this.$route.params.projectId,
            cardInput: {},
            isModalVisible: false,
        };
    },
    methods: {
        createCard(card) {
            projectService
                .createCard(this.projectId, card)
                .then((response) => {
                    const cardDisplay = response.data;
                    const savedCard = cardDisplay.card;
                    this.cards.unshift(savedCard);
                    this.$store.commit("ADD_NOTIFICATION", {
                        type: "success",
                        message: "Card was successfully created",
                    });
                    this.isModalVisible = false;
                })
                .catch((error) => {
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    } else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message:
                                "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error(
                            "Creating card was unsuccessful: ",
                            response?.message
                        );
                    }
                });
        },
        showModal(cardType) {
            this.cardInput = {
                cardType: cardType,
                projectId: this.projectId,
                cardState: "NEW",
            };
            this.isModalVisible = true;
        },
        loadCards(projectId) {
            projectService
                .getProjectBacklog(projectId)
                .then((response) => {
                    const backlogDisplay = response.data;
                    this.cards = backlogDisplay.cards;
                    this.cardTypes = backlogDisplay.cardTypes;
                    this.cardStates = backlogDisplay.cardStates;
                    this.users = backlogDisplay.projectUsers;
                    this.sprints = backlogDisplay.projectSprints;
                    this.isLoading = false;
                    this.isError = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    let response = error.response;
                    if (response?.status === 401) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "Session timeout. Please login again.",
                        });
                        this.$store.commit("LOGOUT");
                        this.$router.push({ name: "login" });
                    } else if (response?.status === 404) {
                        // Token expired
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message: "This project does not exist",
                        });
                        this.isError = true;

                    } else {
                        // Something else unexpected happened
                        this.$store.commit("ADD_NOTIFICATION", {
                            type: "error",
                            message:
                                "Sorry, something unexpected occurred. Please try again later.",
                        });
                        console.error(
                            "Getting cards was unsuccessful: ",
                            response?.message
                        );
                        this.isError = true;
                    }
                });
        },
    },
};
</script>
<style scoped>
.button-group {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-evenly;
}

.img-icon {
    margin: 0 4px;
}

.btn {
  font-size: 20px;
  background-color: transparent;
  padding: 1%;
  border: 0.5px solid white;
  border-radius: 3px;
  color: white;
  font-family: "MedievalSharp", cursive;
}

.btn:hover {
  color: rgb(202, 3, 3);
}

.no-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.no-items-image {
    width: 20rem;
    height: 20rem;
    border-radius: 20rem;
}

.top-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    padding: 3%;
    border: 0.5px solid white;
    border-radius: 5px;
    background-color: rgba(18, 18, 18, 0.75);
}

.title {
    margin-top: 5%;
}

</style>

